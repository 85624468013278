import React, { Component } from 'react';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/entrance.jpg";
import image2 from "../../../../../../Assets/imgs/Sub_Solutions/entrance_advantages.jpg";

class EntranceManagement extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="EntranceManagement">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Entrance Management</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="main-para">Entrance management system, also termed as visitor management, tracks the usage of an entrance of a building/site. The basic objective of such this system is to gather large amounts of information including recording the usage of facilities by particular visitors and also documenting the visitor’s whereabouts. At the most basic level, Visitor Management systems aim to keep an eye on who is on your premises - be it an educational institute, corporate space or even your home.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="Entrance Management" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Utilizing Entrance Management Systems</h4><strong>✔ Bollards</strong> - A bollard refers to a sturdy, short, vertical post. Earlier, a ship or quay used bollards principally for mooring boats. Eventually, it started being used to refer to posts to control road traffic. These are designed to deter ram raiding and car ramming attacks. These are also termed as parking bollards due to increased use in the parking lots. Capital houses automated bollards to prevent unauthorized entry to corporate space and provide access solely to individuals with clearance.<br /><strong>✔ Boom Barriers</strong> - A boom barrier is basically a pole or bar which is pivoted from a fixed point to move in a vertical direction. The purpose of this bar or pole is to block the access of any vehicle or a person through the entrance. Since these are most commonly used at checkpoints, they are also referred to as boom barrier gates. Aligning with the market development, Capital has manufactured world class automatic boom barriers for an affordable price. It has been integrated with ultra-functional infra-red detectors to provide top notch security and prevent vehicles from unauthorized access.</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Advantages of Entrance Management System</h4>Installing visitor management system has a number of benefits:
            <ol>
                <li>ID Data Capture is fully automated</li>
                <li>Screening is automatic and also, instant</li>
                <li>Proper data is visible on visitor badges</li>
                <li>“Smart” Alert Notifications</li>
                <li>Access is completely cloud based</li>
              </ol>
            </p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image2} alt="Entrance Management" className="solutions-image-md" /></div>
          </div>
        </div>
      </div>
    );
  }
}

export default EntranceManagement;