import React, { Component } from 'react';
import './Products.scss';
import PageHeader from "../../../Assets/imgs/sub_solution.jpg";
import Template from "./ProductsTemplate"

class Products extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="Products">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="section-heading">
          <h2>Products Categories</h2>
        </div>
        <div className="container">
          <div className="row">
            <Template />
          </div>
        </div>
      </div>
    );
  }
}

export default Products;