import React, { Component } from "react";
import "./ContactUs.scss";
import ConsultationWidget from "../../../Core/ConsultationWidget/ConsultationWidget";
import CardWidget from "../../../Core/CardWidget/CardWidget";
import PageHeader from "../../../Assets/imgs/sub_solution.jpg";

const mapStyles = {
  width: '100%',
  border: 0,
  pointerEvents: 'stroke'
};

class ContactUs extends Component {
  // constructor(props){
  // super(props);
  // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div>
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <ConsultationWidget heading="Get In Touch With Us" showSubject={true} />
        <CardWidget />
        {/* <iframe title="maps"
          width="600"
          height="450"
          // frameborder="0"
          style={mapStyles}
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyALyMyUBHJlektfObiFPTKxOZ7-2BdtoOc&q=CAPITAL+INFORMATION+TECHNOLOGY+SYSTEM+EST&q=Capital+Information+Technology+System+Est."
          // allowfullscreen
        ></iframe> */}
        {/* <iframe title="maps" src="https://maps.google.com/maps?q=Prince%20Badar%20Street%2C%20A%20Cross%20Al%20Khobar&t=m&z=10&output=embed&iwloc=near&zoom=10" width="600" height="450" frameborder="0" style={mapStyles} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
        <iframe title="maps" src="https://www.google.com/maps/d/embed?mid=1HJvpgObgEmxG-iSDmvkhWw4aSSsOVMVh" width="640" height="480" frameborder="0" style={mapStyles} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </div>
    );
  }
}

export default ContactUs
