import React, { Component } from 'react';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/ups.jpg";
import image2 from "../../../../../../Assets/imgs/Sub_Solutions/ups_power.jpg";
import image3 from "../../../../../../Assets/imgs/Sub_Solutions/power.jpg";

class Power extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="Power">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Power</h2>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col">
              <p className="main-para">Though ‘fire extinguisher’ is the first name that probably comes to your mind when talking about fire-fighting, you must also be aware that a well-sought <span>fire hydrant system</span> and <span>sprinkler system</span> is equally responsible for eradicating fatal fire accidents. While <span>fire hydrant systems,</span> also known as <span>hydrant boosters</span> and <span>firewater pumps</span> are specialized high pressure water pumps curated to enhance the fire-fighting capacity of a building by releasing the pressure when mains fail, a <span>sprinkler system</span> is an active fire protection device which makes use of adequate pressure and flowrate through a water distribution piping system, to control fire. Cool and care blends all the goodness together, to design and manufacture hydrant systems as well as <span>sprinkler systems</span> that enhance fire safety at your home, workplace or elsewhere!</p>
            </div>
          </div> */}
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="Power" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>UPS &amp; Inverters</h4>A steady rise in the embrace of complex facilities and interconnected networks has deteriorated the output of power quality quite conspicuously. To cope with a crisis as such, and protect your equipment from voltage sags, line noise, surges, low-voltage occurrences, swells and harmonics, all you need is Capital’s power solutions. No matter what the level of reliability your organization demands, our power supply solutions can help you get your job done and let your company operations function smoothly.<br />Capital offers a range of technologically advanced UPS systems which ranges from 100 VA to 1 MW or more, single phase to 3 phases, and has a run time of 3 minutes to several hours. Capital also provides you with novel management and monitoring power quality alternates for your data center infrastructure equipment. Our power monitoring solutions include software, network management cards, and instruments for UPS management, monitoring and safe data center shutdown system.</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para">We understand the crucial significance of UPS systems, and therefore provide you with the best range of solutions to maximize your IT up-time.<br />Our inverted systems provide you with a high load capacity with a long service life. Inverts are responsible for converting direct current (DC) to alternating current (AC) to supply power to various equipment which ranges from the most critical telecommunications equipment to other equipment like tools and computers. Given the performance, design and functionality of our inverted systems, we ensure you expandable power levels and the cleanest true sine wave in your power inverts.</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image2} alt="Power" className="solutions-image-md" /></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image3} alt="Power" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para">You may rely on our alternate AC inverter systems during utility power problems such as blackouts, voltage fluctuations and surges. Usually, when utility power is present, an inverted facilitates power supply to your equipment alongside simultaneously charging all your connected batteries. However, in case of a blackout when the utility power supply is snapped, inverters shall switch automatically from utility power to a backup battery power.<br />So, if you are in need of the smallest switch-mode inverter or a large industrial inverter system, Capital promises to offer you the best solutions in terms of performance levels, robust design and high reliability. Our team of expert professionals backed with years of knowledge and experience in power electronics has delivered proven results while offering total site power solutions. We also ensure complete environment-friendliness as all our power solutions function noiselessly without emitting any noxious fumes that may possibly harm your business environment.</p></div>
          </div>
          {/* <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para">We, at Capital, specialize in providing you with matchless solutions of Fire & Safety equipment which possess high-end capabilities of carrying out an extinguishing performance unerringly. Further, our <span>fire hydrant and sprinkler systems</span> reassure you of quality standards and higher durability.</p></div>
            <div className="col"></div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default Power;