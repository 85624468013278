import React, { Component } from 'react';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/network_cabling.jpg";
import image2 from "../../../../../../Assets/imgs/Sub_Solutions/storage.jpg";
// import image3 from "../../../../../../Assets/imgs/Sub_Solutions/co2.png";

class PassiveNetworking extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="PassiveNetworking">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Passive Networking</h2>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col">
              <p className="main-para">Though ‘fire extinguisher’ is the first name that probably comes to your mind when talking about fire-fighting, you must also be aware that a well-sought <span>fire hydrant system</span> and <span>sprinkler system</span> is equally responsible for eradicating fatal fire accidents. While <span>fire hydrant systems,</span> also known as <span>hydrant boosters</span> and <span>firewater pumps</span> are specialized high pressure water pumps curated to enhance the fire-fighting capacity of a building by releasing the pressure when mains fail, a <span>sprinkler system</span> is an active fire protection device which makes use of adequate pressure and flowrate through a water distribution piping system, to control fire. Cool and care blends all the goodness together, to design and manufacture hydrant systems as well as <span>sprinkler systems</span> that enhance fire safety at your home, workplace or elsewhere!</p>
            </div>
          </div> */}
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="Passive Networking" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Structured Cabling</h4>A good structured cabling system is imperative to have high availability and integrity, and secure IT systems. In reality, cabling systems are responsible for providing a fool-proof way to manage your connectivity in your business environment for various applications. So, ideally, your cabling systems must have an immunity to accommodate newer and advanced technologies of the future. Our structured cabling solutions are futureproof, upgradable and flexible. From designing new installations to reconditioning existing networks, system upgrades to moves, additions or modifications, network consulting to testing, documentation and maintenance of cabling systems, Capital is your one-stop solution to all your structured cabling needs.<br />We help buildings achieve its complete communication potential through good planning, robust designing, professional installation and appropriate maintenance of your cabling systems. Our designs help you manage your network infrastructure easily and also allow you to add, move, or remove elements within a minimum downtime.<br />If you are looking to get a network infrastructure that helps you run applications at optimum performance levels, at affordable prices, Capital’s solutions will add value to your business and a technological edge over your competitors.</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Network Racks</h4>While designing a data center, one of the foremost things to give utmost importance to is deploying a suited network rack or cabinet to hold your servers and network equipment. An increasing demand for computing power and space in a data center makes it compulsory for you to make appropriate arrangements in terms of stacking the right equipment which can hold all your networking hardware and assemblies in one place harmoniously.<br />Capital provides you with high-quality structures of installation which supports your electronic equipment, power, cooling, and cable management, thereafter organizing and securing your network, storage and telecom equipment in data centers, server rooms and wiring closets.<br />Our network rack help you improve power protection, cooling, cable management and physical security measures in an IT environment. They ensure you of correct organization and management of your network equipment, and keep it from being tampered from unwanted attacks. They also enable easy maintenance and troubleshooting thereby enhancing smooth business operations.<br />In a nutshell, our network rack in a way maximize your work efficiency and minimize possible network downtime risks.</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image2} alt="Passive Networking" className="solutions-image-md" /></div>
          </div>
        </div>
      </div>
    );
  }
}

export default PassiveNetworking;