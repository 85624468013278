import React, { Component } from 'react';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/cctv.png";
import image2 from "../../../../../../Assets/imgs/Sub_Solutions/cctv-systems.webp";

class VideoSurveillance extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="VideoSurveillance">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Video Surveillance</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="main-para">Streamlining your business in the right way can be challenging if you do not score high on visibility and insights. While it’s important to ensure your employees are working efficiently, it is equally essential to be able to monitor your work space while you are away. Whether you have one location to manage or a number of offices spread across distant places, an effective video surveillance system deters crime and also, captures and keeps track of security events occurring in and around your premises. The CCTV system housed by Capital keeps an eye on your workplace and home, allowing you to focus on pressing issues while away.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="CCTV Systems" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>CCTV security surveillance systems</h4>A CCTV security camera is a crucial component of a comprehensive security strategy. Capital has in store the expertise and experience to manufacture, set up, service, and track a system that is the best fit for your own unique business requirements and synchronizes with your systems in use. Whether you’re in search of a home security camera or corporate surveillance systems, Capital has the ultimate solution.</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Camera Systems can help you perform the following functions without any errors</h4>✔ Visitor management to ensure people only have access to places they require to go.<br />✔ Controlling access through updated technology to avoid security threats.<br />✔ High quality recording and safe storage so that you have a hold of all that goes on in your workplace, not just in your presence but also when you are physically absent.<br />The use of Video Surveillance has evolved from a luxury to a necessity. This added protection leads to a safer environment for employees to thrive in, which will enhance your business prospects. Investing in Capital’s CCTV system can bring peace of mind and create a secure environment for any business.</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image2} alt="CCTV Systems" className="solutions-image-md" /></div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoSurveillance;