import React, { Component } from 'react';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/storage.jpg";
import image2 from "../../../../../../Assets/imgs/Sub_Solutions/storage_servers.jpg";
import image3 from "../../../../../../Assets/imgs/Sub_Solutions/converged_data.svg";
// import image4 from "../../../../../../Assets/imgs/Sub_Solutions/co2.png";

class Enterprise extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="Enterprise">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Enterprise</h2>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col">
              <p className="main-para">Though ‘fire extinguisher’ is the first name that probably comes to your mind when talking about fire-fighting, you must also be aware that a well-sought <span>fire hydrant system</span> and <span>sprinkler system</span> is equally responsible for eradicating fatal fire accidents. While <span>fire hydrant systems,</span> also known as <span>hydrant boosters</span> and <span>firewater pumps</span> are specialized high pressure water pumps curated to enhance the fire-fighting capacity of a building by releasing the pressure when mains fail, a <span>sprinkler system</span> is an active fire protection device which makes use of adequate pressure and flowrate through a water distribution piping system, to control fire. Cool and care blends all the goodness together, to design and manufacture hydrant systems as well as <span>sprinkler systems</span> that enhance fire safety at your home, workplace or elsewhere!</p>
            </div>
          </div> */}
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="Extinguishers" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Storage &amp; Computing</h4>While your network and computing elements may last for several years, your storage capacity may not. It is safe to say that the exponential growth of business data is only pointing at the inadequacy of enterprise storage capacity in present times. We, at Capital are focused on offering your organization a state-of-art IT infrastructure solution. As a result, we extract greater business value from your enterprise data through optimum enterprise storage assessment, architecture, deployment and management. Our data and storage management services help you cope with a greater volume, variety and velocity of data, doubly ensuring that you can protect and manage your data optimally at the core. Our holistic approach to data storage and management not only helps maximize availability for your users but also drastically reduces potential business risks.</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para">Apart from assessing current technologies, upgrades, archival and data storage requirements, and compliance and application requirements, we also analyze the bandwidth of consumption, their access points and security. From virtualization and de-duplication to multi-tiered architectures and hybrid infrastructures, we assist you in defining strategies, design solutions and maintaining environments.<br />We consolidate data storage platforms, simplify backup processes, accelerate recovery mechanisms and enable analytics, thereafter increasing utilization, decreasing costs and safeguarding compliance guidelines. Our industrialized approach gives you a clearer insight of your product roadmap liaised with industry trends, helps you evaluate solutions in a risk-free environment and get end-to-end infrastructure expertise. Capital understands the importance of a business’s data and provides modern solutions to store and manage it efficiently.</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image2} alt="Extinguishers" className="solutions-image-md" /></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image3} alt="Extinguishers" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Converged/Hyper-Converged Infrastructure</h4>At Capital we offer cost-effective and well-sought converged/hyper converged infrastructure solutions to help utilize your computer resources more efficiently. Furthermore, these solutions bring down your IT management costs, improve agility and flexibility in a business environment, reduce storage and bandwidth requirements, and increase the speed of your software and service deployment at ease.<br />A unique approach of our solution architects and technical support teams in deliberating Converged/Hyper Converged Infrastructure Solutions lies in binding together virtualization, automation and unified management infrastructure management software into a pre-built, tested and workload-optimized systems, which are software-defined for easy integration into the existing infrastructure and quick transition to hybrid cloud delivery models. Capital offers hassle-free and simplified IT operations for any environment!</p></div>
          </div>
          {/* <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para">We, at Capital, specialize in providing you with matchless solutions of Fire & Safety equipment which possess high-end capabilities of carrying out an extinguishing performance unerringly. Further, our <span>fire hydrant and sprinkler systems</span> reassure you of quality standards and higher durability.</p></div>
            <div className="col"></div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default Enterprise;