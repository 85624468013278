import React, { Component } from "react";
import "./Cards.scss";

class Cards extends Component {
  // constructor(props){
  // super(props);
  // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="card-set" className="container">
        <div className="row">
          <div className="col">
            <div className="section-heading">
              <h2>{this.props.heading}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-lg-4">
            <div className="card blue-border">
              <i className="fas fa-rocket"></i>
              <h3>MISSION</h3>
              <p>
                Right solutions keep businesses in shape. That’s the only
                possible way to be in momentum and drive success. Thus, our
                mission is to align our practices and measures in such a way
                that the resources and IT Services we offer allow our clients to
                starve and evolve their business bottom line. It matters!
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-4">
            <div className="card purple-border">
              <i className="fas fa-rocket"></i>
              <h3>VISION</h3>
              <p>
                The change can never be overwhelmed. It’s because it is attended
                with persistence and hard work. That’s called an innovative
                approach. Our vision is to help every business organization
                seeking robust IT & Technology Solutions to further making their
                functioning seamless and remarkably effective. The best part is
                – Our solutions make the difference that we are having pride of.{" "}
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-4">
            <div className="card green-border">
              <i className="fas fa-rocket"></i>
              <h3>VALUES</h3>
              <p>
                We immensely value amazing folks working with us. That’s their
                efforts and determination that we are able to cope up with the
                future challenges and evolving needs of target industries at
                large. Similarly, what matters to us is delivering satisfactory
                solutions, so our clients experience utmost care and attention
                towards them. It is what makes the most out of everything!
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Cards;
