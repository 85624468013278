import React, { Component } from 'react';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/network.png";
import image2 from "../../../../../../Assets/imgs/Sub_Solutions/network_team.png";
import image3 from "../../../../../../Assets/imgs/Sub_Solutions/security.png";
import image4 from "../../../../../../Assets/imgs/Sub_Solutions/network-security.png"; 
import image5 from "../../../../../../Assets/imgs/Sub_Solutions/firewall.webp"; 

class NetworkSecurity extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="NetworkSecurity">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Network Security</h2>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col">
              <p className="main-para">Though ‘fire extinguisher’ is the first name that probably comes to your mind when talking about fire-fighting, you must also be aware that a well-sought <span>fire hydrant system</span> and <span>sprinkler system</span> is equally responsible for eradicating fatal fire accidents. While <span>fire hydrant systems,</span> also known as <span>hydrant boosters</span> and <span>firewater pumps</span> are specialized high pressure water pumps curated to enhance the fire-fighting capacity of a building by releasing the pressure when mains fail, a <span>sprinkler system</span> is an active fire protection device which makes use of adequate pressure and flowrate through a water distribution piping system, to control fire. Cool and care blends all the goodness together, to design and manufacture hydrant systems as well as <span>sprinkler systems</span> that enhance fire safety at your home, workplace or elsewhere!</p>
            </div>
          </div> */}
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="Network Security" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para">The changing data security threats in IT organizations have mandated an evolution of network security systems. Threats related to theft and malware attacks are increasing at an alarming speed and trends of mobility within an organizational setup, such as, BYOD (Bring Your Own Device), are only adding on to complicating the scenario a lot more. Network administrators are under an increasing pressure to identify potential threats from within an advanced and complex technological network that often fails to provide adequate visibility. Capital designs network security solutions with current technologies and ensures optimum safety of your organizations from malicious threats. Our unique approach harps on revolutionizing network security into being application aware and therefore leads to an absolute mitigation of application threats. Our unique approach to creating a resilient and reliable network infrastructure is secured from the edge to the core, from client to server.</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para">While network security management in recent times is quite a cumbersome task, it is also another fact that superior network security mechanisms can help make the task a lot simpler. Network security solutions at Capital enable you to simplify management alongside improving security and performance. The over saturation of technology and an in-house network security team with constrained resources has made choosing the ideal network security solutions for your IT environment supremely challenging. This is the reason why many organizations are turning to Capital for a successful evaluation and implementation of network security solutions, staying one step ahead from potential bottlenecks.</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image2} alt="Network Security" className="solutions-image-md" /></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image3} alt="Network Security" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para">Our expert team of professionals are dedicated to offering bona-fide network security solutions to our diverse clientele, thereby ensuring the latter of cohesion, reliability, and performance levels. Our team helps you evaluate your current infrastructure and then provide you with a plan that triggers correct implementation of network security solutions, services and products to add unprecedented value to your IT environment alongside solving your network security problems effectively.</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para">Our network security solutions include:
              <ul>
                <li>Identity Management</li>
                <li>Application Security</li>
                <li>Email Security</li>
                <li>Unified Threat Management</li>
                <li>Web Security</li>
                <li>Endpoint Security</li>
              </ul>
              Our network security solutions provide native security and encryption capabilities which deliver efficient and flexible outputs, thereafter reassuring protection of a wide range of networks. We in collaboration with our security partners enable businesses to deploy and manage secure networks with best results. In a nutshell, we are aimed at providing pre-emptive security solutions to your business environment that helps you ensure regulatory compliance and business continuity.</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image4} alt="Network Security" className="solutions-image-md" /></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image5} alt="Network Security" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Firewall Security</h4>Monitoring and controlling incoming and outgoing network traffic is worth the time and company safety. It’s because this functioning has the power to make or break the deal for a lot of businesses at large. What it makes is the way such network capabilities are placed forth, so every operation will have a check in order to pass through. The best part is – It will reduce the data leak, hack as well as other suspicious activities. Tracking means controlling your business environment and functioning in the best possible manner. Thus, it’s good to talk about our Firewall Services to improve your business bottom line and security at large. Contact us, today!</p></div>
          </div>
        </div>
      </div>
    );
  }
}

export default NetworkSecurity;