import React, { Component } from 'react';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/alarm.jpeg";

class AlarmSystems extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="AlarmSystems">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Alarm Systems</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="main-para">Alarm systems have seen a steep hike in its usage over the last few years as the need of addressing security issues has become one of the most important criteria in corporate sectors as well as at residential properties. Paired with a security camera system, an alarm system deters unauthorized access to all assets placed under the surveillance. Even though business alarm systems were more sought-after in the past, the demand for home alarm systems has increased to comparable levels.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="Alarm Systems" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Here’s how alarm systems can help you keep your business environment or home secure:</h4><br /><strong>✔ Protecting Valuables</strong> - The prime objective of an alarm system is to provide protection to your valuables. A home alarm system makes sure unauthorized entry is immediately identified and a business alarm system keeps your workplace safe.<br /><strong>✔ Preventing Crime/Access Control</strong> - Alarm systems provide a higher level of security, creating a more secure business and home environment. Unwanted individuals can be identified immediately and this can prevent any potential damage caused by the intruder.<br /><strong>✔ Notifies You of Fire or Gas Problems</strong> - An alarm system notifies you when smoke or carbon monoxide gas is detected in air so that you can take necessary actions in a timely manner.<br /><strong>✔ Keeping People Safe from Dangerous Devices</strong> - Alarm systems have sensors, which keep people away from specific devices. This can prevent harm to individuals and sensitive equipment.<br />Preventative technology such as home alarm monitoring and radar alarm systems creates a safer living and work environment. Capital’s mission is to provide your business with state-of-the-art technology to keep up with evolving and ever-present security issues.</p></div>
          </div>
        </div>
      </div>
    );
  }
}

export default AlarmSystems;