import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Company.scss";
import PageHeader from "../../../Assets/imgs/about_us.jpg";
import sideImage from "../../../Assets/imgs/image-overlay.png";
import CardWidget from "../../../Core/CardWidget/CardWidget";

import apcLogo from '../../../Assets/imgs/Client_Logos/apc.png'
import altonLogo from '../../../Assets/imgs/Client_Logos/alstonsystems.png'
import avayaLogo from '../../../Assets/imgs/Client_Logos/avaya.png'
import dlinkLogo from '../../../Assets/imgs/Client_Logos/dlink.png'
import dellLogo from '../../../Assets/imgs/Client_Logos/dell.png'
import yealinkLogo from '../../../Assets/imgs/Client_Logos/yealink.png'
import hpLogo from '../../../Assets/imgs/Client_Logos/hp.png'
import systimaxLogo from '../../../Assets/imgs/Client_Logos/systimax.png'
import panasonicLogo from '../../../Assets/imgs/Client_Logos/panasonic.png'
import beldenLogo from '../../../Assets/imgs/Client_Logos/belden.svg'
import ciscoLogo from '../../../Assets/imgs/Client_Logos/cisco.svg'
import arubaLogo from '../../../Assets/imgs/Client_Logos/aruba1.svg'
import linksysLogo from '../../../Assets/imgs/Client_Logos/linksys.svg'
import threemLogo from '../../../Assets/imgs/Client_Logos/3M.png'
import tplinkLogo from '../../../Assets/imgs/Client_Logos/tplink.svg'

class Company extends Component {
  // constructor(props){
  // super(props);
  // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1300,
      pauseOnHover: true
    };
    return (
      <div id="company-page">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <CardWidget
          heading="We are Capital"
          content="Building Solutions and establishing a culture of prosperity and betterment to address particular needs of our customers. We have got everything you ever need to sustain in an online world."
        />
        <div class="container-fluid bg-archived-number">
          <div class="row">
            <div class="container">
              <div id="counter" class="row">
                <div class="col-sm-12 col-md-3 text-center">
                  <div class="arch-no-box">
                    <div class="count-main">
                      <span class="counter-value" data-count="300">
                        300
                      </span>
                      <span class="count-plus">+</span>
                    </div>
                    <h3>Happy Clients</h3>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3 text-center">
                  <div class="arch-no-box">
                    <div class="count-main">
                      <span class="counter-value" data-count="13">
                        13
                      </span>
                      <span class="count-plus">+</span>
                    </div>
                    <h3>Year in Bussiness</h3>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3 text-center">
                  <div class="arch-no-box">
                    <div class="count-main">
                      <span class="counter-value" data-count="100">
                        100
                      </span>
                      <span class="count-plus">+</span>
                    </div>
                    <h3>Satisfaction</h3>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3 text-center">
                  <div class="arch-no-box">
                    <div class="count-main">
                      <span class="counter-value" data-count="50">
                        50
                      </span>
                      <span class="count-plus">+</span>
                    </div>
                    <h3>Employees</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row about-section">
          <div className="col-xs-12 col-md-6 col-lg-6">
            <h3>Who We Are</h3>
            <p>
              You can call us – An International Provider of Market-Leading
              Software &amp; Services. The best part is – We have worked in
              different verticals and delivered remarkable solutions pertaining
              to needs and market demand. That’s what changes the complete
              functioning and doing businesses for our customers. Our solutions
              are diverse and mission-critical to address specific needs of our
              particular industries. So, it’s great to discuss your requirement
              and have fruitful talks, indeed. Isn’t it? Get in touch, today!
            </p>
          </div>
          <div className="col-xs-12 col-md-6 col-lg-6">
            <img src={sideImage} alt="" className="image"/>
          </div>
        </div>
        <div className="section-heading text-center">
            <h2>Our Partners</h2>
        </div>
        <Slider {...settings} className="slick-carousel">
          <div><img src={ciscoLogo} alt="Client Logo" className="partner-logos-sm"/></div>
          <div><img src={avayaLogo} alt="Client Logo" className="partner-logos"/></div>
          <div><img src={arubaLogo} alt="Client Logo" className="partner-logos-sm"/></div>
          <div><img src={yealinkLogo} alt="Client Logo" className="partner-logos"/></div>
          <div><img src={panasonicLogo} alt="Client Logo" className="partner-logos"/></div>
          <div><img src={apcLogo} alt="Client Logo" className="partner-logos"/></div>
          <div><img src={altonLogo} alt="Client Logo" className="partner-logos"/></div>
          <div><img src={systimaxLogo} alt="Client Logo" className="partner-logos"/></div>
          <div><img src={beldenLogo} alt="Client Logo" className="partner-logos"/></div>
          <div><img src={threemLogo} alt="Client Logo" className="partner-logos-sm"/></div>
          <div><img src={linksysLogo} alt="Client Logo" className="partner-logos"/></div>
          <div><img src={dlinkLogo} alt="Client Logo" className="partner-logos"/></div>
          <div><img src={tplinkLogo} alt="Client Logo" className="partner-logos-sm"/></div>
          <div><img src={dellLogo} alt="Client Logo" className="partner-logos-sm"/></div>
          <div><img src={hpLogo} alt="Client Logo" className="partner-logos-xsm"/></div>
          {/* <div><img src={rittalLogo} alt="Client Logo" className="partner-logos-xsm"/></div> */}
        </Slider>
        
      </div>
    );
  }
}

export default Company;
