import React, { Component } from 'react';
// import './FireExtinguishers.scss';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/extinguishers_types.jpg";
import image2 from "../../../../../../Assets/imgs/Sub_Solutions/powder.png";
import image3 from "../../../../../../Assets/imgs/Sub_Solutions/foam_based.jpg";
import image4 from "../../../../../../Assets/imgs/Sub_Solutions/co2_based.jpg";

class FireExtinguishers extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="FireExtinguishers">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Fire extinguisher & standalone extinguisher</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="main-para">Whether it’s a corporate area or home space, having an effective fire extinguishing system installed is the wisest idea to deter destruction due to fire. A fire extinguisher, an active device aligned with the concept of fire protection, is typically used to extinguish or control minor fires, mostly in urgent situations. Though it does not ensure complete protection against an out-of-control fire, like if it has reached the ceiling, blocks all escape routes, emits smoke, causes explosion hazard, etc. but helps managing it to some extent unless the expertise of a fire brigade arrive and take over. Generally, a fire extinguisher is a device which consists of a cylindrical pressure vessel filled with a fire extinguishing agent which is to be discharged to serve the purpose. There are different types of extinguishing agents to select from based on the type of risk the place carries. So, wait no more, install the most reliable fire extinguishing system from our array of products, and stay guarded from unwanted fire accidents.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="Extinguishers" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Different Types of Fire Extinguisher &amp; Standalone Extinguisher</h4>Capital cares for your protection and puts your safety first. So, we provide you with four different types of fire extinguisher and specialized standalone extinguisher to keep your premises ready to fight fire efficiently. Here’s what you should opt for, and why!</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Powder Based Fire Extinguisher</h4>Powder based blue-labelled fire extinguishers have the ability to tackle fire caused by three types of sources, namely - organic materials, flammable liquids & gases and electrical equipment. This device attempts to smother fire by forming an obstacle between the fuel and the O2 source.</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image2} alt="Extinguishers" className="solutions-image-md" /></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image3} alt="Extinguishers" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Foam Based Fire Extinguisher</h4>Foam extinguishers are cream labelled devices that can handle fires caused by organic materials as well as flammable liquids.</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Gas/ CO2 Based Fire Extinguisher</h4>These are predominantly used for electrical fire risks and are most commonly used in computer server rooms. This can also tackle fire caused by flammable liquids. It aims to extinguish fire by displacing and suffocating the oxygen that the fire consumes to burn.</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image4} alt="Extinguishers" className="solutions-image-md" /></div>
          </div>
        </div>

      </div>
    );
  }
}

export default FireExtinguishers;