import React, { Component } from 'react';
import './BusinessAssociates.scss';
import PageHeader from '../../../Assets/imgs/about_us.jpg'
import apcLogo from '../../../Assets/imgs/Client_Logos/apc.png'
import altonLogo from '../../../Assets/imgs/Client_Logos/alstonsystems.png'
import avayaLogo from '../../../Assets/imgs/Client_Logos/avaya.png'
import dlinkLogo from '../../../Assets/imgs/Client_Logos/dlink.png'
import dellLogo from '../../../Assets/imgs/Client_Logos/dell.png'
import yealinkLogo from '../../../Assets/imgs/Client_Logos/yealink.png'
import hpLogo from '../../../Assets/imgs/Client_Logos/hp.png'
import systimaxLogo from '../../../Assets/imgs/Client_Logos/systimax.png'
import panasonicLogo from '../../../Assets/imgs/Client_Logos/panasonic.png'
import beldenLogo from '../../../Assets/imgs/Client_Logos/belden.svg'
import ciscoLogo from '../../../Assets/imgs/Client_Logos/cisco.svg'
import arubaLogo from '../../../Assets/imgs/Client_Logos/aruba1.svg'
import linksysLogo from '../../../Assets/imgs/Client_Logos/linksys.svg'
import threemLogo from '../../../Assets/imgs/Client_Logos/3M.png'
import tplinkLogo from '../../../Assets/imgs/Client_Logos/tplink.svg'
class BusinessAssociates extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div className="business-associates">
        <img src={PageHeader} className="page-header" alt="PageHeader"/>
        <div className="container">
          <div className="row">
            <div className="col section-heading"><h2>Our Partners</h2></div>
          </div>
          <div className="row">
            <div className="col">
              <img src={ciscoLogo} className="logo-images-sm" alt=""/>
            </div>
            <div className="col">
              <img src={avayaLogo} className="logo-images" alt=""/>
            </div>
            <div className="col">
              <img src={arubaLogo} className="logo-images" alt=""/>
            </div>
            <div className="col">
              <img src={yealinkLogo} className="logo-images" alt=""/>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img src={panasonicLogo} className="logo-images" alt=""/>
            </div>
            <div className="col">
              <img src={apcLogo} className="logo-images" alt=""/>
            </div>
            <div className="col">
              <img src={altonLogo} className="logo-images" alt=""/>
            </div>
            <div className="col">
              <img src={systimaxLogo} className="logo-images" alt=""/>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img src={beldenLogo} className="logo-images" alt=""/>
            </div>
            <div className="col">
              <img src={threemLogo} className="logo-images-sm" alt=""/>
            </div>
            <div className="col">
              <img src={linksysLogo} className="logo-images" alt=""/>
            </div>
            <div className="col">
              <img src={dlinkLogo} className="logo-images" alt=""/>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img src={tplinkLogo} className="logo-images" alt=""/>
            </div>
            <div className="col">
              <img src={dellLogo} className="logo-images" alt=""/>
            </div>
            <div className="col">
              <img src={hpLogo} className="logo-images-sm" alt=""/>
            </div>
            {/* <div className="col">
              <img src={legrandLogo} className="logo-images" alt=""/>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default BusinessAssociates;