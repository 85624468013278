import React, { Component } from 'react';
// import './SpecializedExtinguishers.scss';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/extinguishers.png";

class SpecializedExtinguishers extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="SpecializedExtinguishers">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Specialized Stand Alone Extinguisher</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="Specialized Extinguishers" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para">These differ from the aforementioned by means of its rugged construction and low maintenance, and are suitable for almost every premise including vehicles to ensure safety against fire. Choose from our diverse range of fire extinguishing systems and re-ensure your safety and security like no other!</p></div>
          </div>
        </div>
      </div>
    );
  }
}

export default SpecializedExtinguishers;