import React, { Component } from 'react';
import './ErrorPage.scss';
import PageHeader from "../../../Assets/imgs/about_us.jpg";
import errorMsg from "../../../Assets/imgs/giffy.gif";

class ErrorPage extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="errorPage">
        {/* <img src={PageHeader} className="page-header" alt="PageHeader" /> */}
        <img src={errorMsg} className="page-header" alt="PageHeader" />
      </div>
    );
  }
}

export default ErrorPage;