import React from "react";
import Card from 'react-bootstrap/Card';

import Accordion from 'react-bootstrap/Accordion';
// Product Images
import cabinet from "../../../Assets/imgs/Products/cabinet.jpg"
import ups from "../../../Assets/imgs/Products/ups.jpg"
import switches from "../../../Assets/imgs/Products/cisco_switch.jpg"
import ipTelephone from "../../../Assets/imgs/Products/ip_telephone.jpg"
import coreSwitch from "../../../Assets/imgs/Products/core_switch.jpeg"
import router from "../../../Assets/imgs/Products/router.jpeg"
import patchCords from "../../../Assets/imgs/Products/patch_cords.jpg"
import patchPanel from "../../../Assets/imgs/Products/patch_panel.jpg"
import facePlates from "../../../Assets/imgs/Products/faceplates.jpg"
import utpstp from "../../../Assets/imgs/Products/utp_stp.jpg"
import keystone from "../../../Assets/imgs/Products/keystone.jpg"
import otdr from "../../../Assets/imgs/Products/otdr.jpg"
import copperTesting from "../../../Assets/imgs/Products/copper_testing.png"
import fibrePreparation from "../../../Assets/imgs/Products/fibre_preparation.jpg"
import flukeCopper from "../../../Assets/imgs/Products/fluke_copper.jpg"
import fibreTesting from "../../../Assets/imgs/Products/fibre_testing.jpg"
import copperCrimping from "../../../Assets/imgs/Products/copper_crimping.jpg"
import networkKits from "../../../Assets/imgs/Products/network_kits.jpg"
import splitter from "../../../Assets/imgs/Products/GEPON_splitter.jpg"
import MPOAssemblies from "../../../Assets/imgs/Products/MPO_assemblies.jpg"
import breakAndfanAssemblies from "../../../Assets/imgs/Products/breakout&fanout_assemblies.jpg"
import fibrePatchPanel from "../../../Assets/imgs/Products/patch_panel_fiber.jpg"
import fibrePatchCord from "../../../Assets/imgs/Products/patch_cords_fiber.webp"
import fibreOptics from "../../../Assets/imgs/Products/fibre_optics.jpg"
import spiceTray from "../../../Assets/imgs/Products/splice_tray.jpg"
import fibreEnclosure from "../../../Assets/imgs/Products/fibre_enclosure.jpg"
import fibreConnector from "../../../Assets/imgs/Products/connectors_fiber.jpg"
import fibreToolKit from "../../../Assets/imgs/Products/fiber_tool_kit.jpg"
import splicingMachine from "../../../Assets/imgs/Products/splicing.png"


const tabs = [
    { id: 1, label: "Cabinets", 
        products: [
            {id: 1, image: cabinet, name: "Cabinets", description: "A standardized frame or enclosure for mounting multiple electronic equipment modules. Each module has a front panel."}
        ]
    },
    { id: 2, label: "Active Networking Products", 
        products: [
            {id: 1, image: ups, name: "UPS", description: "An uninterruptible power supply or uninterruptible power source is an electrical apparatus that provides emergency power to a load when the input power source or mains power fails."},
            {id: 2, image: switches, name: "Switch", description: "Network switch is a multiport network bridge that uses MAC addresses to forward data at the data link layer (layer 2) of the OSI model."},
            {id: 3, image: ipTelephone, name: "IP Telephone", description: "An IP Telephone, very broadly speaking, is a telephone designed to work with an IP PBX. The popularity of the SIP standard, however, means that the IP PBX of today has evolved, almost universally, into a SIP-based PBX."},
            {id: 4, image: coreSwitch, name: "Core Switch", description: "A fiber media converter is a simple networking device that makes it possible to connect two dissimilar media types such as twisted pair with fiber optic cabling."},
            {id: 5, image: router, name: "Routers", description: "Content of Tab 3"},
        ]
    },
    { id: 3, label: "Copper" , 
        products: [
            {id: 1, image: patchCords, name: "Patch Cords", description: "Content of Tab 1"},
            {id: 2, image: patchPanel, name: "Patch Panel", description: "Content of Tab 2"},
            {id: 3, image: facePlates, name: "Faceplates", description: "Content of Tab 3"},
            {id: 4, image: utpstp, name: "UTP/STP Cables", description: "Content of Tab 3"},
            {id: 5, image: keystone, name: "Keystone Modules", description: "Content of Tab 3"},
        ]
    },
    { id: 4, label: "Tools and Testing" , 
        products: [
            {id: 1, image: otdr, name: "OTDR & Power Tester", description: "Content of Tab 1"},
            {id: 2, image: copperTesting, name: "Copper Testing Tools", description: "Content of Tab 2"},
            {id: 3, image: fibrePreparation, name: "Fiber Preparation Tools", description: "Content of Tab 3"},
            {id: 4, image: flukeCopper, name: "Fluke Copper", description: "Content of Tab 3"},
            {id: 5, image: fibreTesting, name: "Fiber Testing Tools", description: "Content of Tab 3"},
            {id: 6, image: copperCrimping, name: "Copper Punching & Crimping Tools", description: "Content of Tab 3"},
            {id: 7, image: networkKits, name: "Network Toolkits", description: "Content of Tab 3"},
            {id: 8, image: splicingMachine, name: "Splicing Machine", description: "Content of Tab 3"},
        ]
    },
    { id: 5, label: "Fiber" , 
        products: [
            {id: 1, image: splitter, name: "GEPON Splitter", description: "Content of Tab 1"},
            {id: 2, image: MPOAssemblies, name: "MPO Assemblies", description: "Content of Tab 2"},
            {id: 3, image: breakAndfanAssemblies, name: "Break Out & Fan Out Assemblies Adapters", description: "Content of Tab 3"},
            {id: 4, image: fibrePatchPanel, name: "Patch Panels", description: "Content of Tab 3"},
            {id: 5, image: fibrePatchCord, name: "Patch Cords", description: "Content of Tab 3"},
            {id: 6, image: fibreOptics, name: "Fiber Optic Cables", description: "Content of Tab 3"},
            {id: 7, image: spiceTray, name: "Splice Tray", description: "Content of Tab 3"},
            {id: 8, image: fibreEnclosure, name: "Enclosures", description: "Content of Tab 3"},
            {id: 9, image: fibreConnector, name: "Connectors", description: "Content of Tab 3"},
            {id: 10, image: fibreToolKit, name: "Faceplate Termination Kits & Tooling", description: "Content of Tab 3"},
        ]
    },
];

export default function ProductsTemplate() {
  // constructor(props){
  // super(props);
  // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

    return (
        <div id="ProductsTemplate">
        {tabs.map((tab, index) => {
            return(

                <Accordion key={tab.id} defaultActiveKey={tab.id} data-toggle="tooltip" data-placement="bottom" title="Click to Collapse">
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey={tab.id}>
                <strong><h5>{tab.label}</h5></strong>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={tab.id}>
                    <div className="row">
                {tab.products.map((product, subindex) => {
                    return(
                        <div className="col">

                        <Card key={product.id}>
                   <Card.Img variant="top" src={product.image} />
                   <Card.Body>
                   <Card.Title>{product.name}</Card.Title>
                   {/* <Card.Text>{product.description}</Card.Text> */}
                   </Card.Body>
                </Card>
                        </div>
                        )
                    })}
                    </div>
                </Accordion.Collapse>
            </Card>
        </Accordion>
        )
        })}
        </div>
    );
  }

