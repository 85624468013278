import React, { Component } from 'react';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/vehicle_scanner.jpg";
import image2 from "../../../../../../Assets/imgs/Sub_Solutions/body_scanner.png";
import image3 from "../../../../../../Assets/imgs/Sub_Solutions/baggage_scanner.png";

class ScanningSystems extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="ScanningSystems">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Scanning Systems</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="sub-para">Scanning is an integral part of physical security. This safety measure aims to control access to equipment, facilities, and all types of resources. The sole purpose of this is to protect workforces, network and property from damage, harm or imposition. By performing a system scan luggage, vehicles, and individuals are scrutinized thoroughly using surveillance cameras and notification systems. Capital houses an array of baggage scanners, vehicle scanners and full body scanners to ensure seamless and hassle-free safety measures for your business or home space.
              <h4>Benefits of a physical scanner</h4></p>
              <p>The benefits of a physical scanner are:<br />✔ Attaining high level of security<br />✔ Deterring unauthorized access<br />✔ Effectively identifying malicious objects</p>              
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="Vehicle Scanner" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Vehicles Scanners</h4>A vehicle scan is performed with the help of an automotive scan tool (scanner). The purpose is to interface with, diagnose and, often reprogram car control modules. The benefits of a car diagnostic scanner are:<br />✔ Enhanced fleet and driver’s safety<br />✔ Easy hints of internal car issues<br />✔ High compatibility</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Full Body Scanner</h4>A full body scanner is used in sensitive locations like airports (and other sites) to scan the entire body and check if any malicious object is being carried by an individual. A full body scanner is beneficial in many aspects as it:<br />✔ Reveals hidden weapons<br />✔ Detects metallic and non-metallic objects<br />✔ Make terrorist attacks more difficult<br />✔ Less intrusive</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image2} alt="Full Body Scanners" className="solutions-image-md" /></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image3} alt="Baggage Scanners" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Baggage Scanners</h4>A baggage scanner is a special compact X-ray equipped system that is meant for high security premises such as Airports, Government offices, Railways and MRTS checkpoints. They are used to scan any baggage size and have a variety of benefits:<br />✔ Speeds up passage<br />✔ Quality display of image<br />✔ Does not require multiple checking<br />✔ Operation is easy & user friendly<br />✔ Superior material identification<br />✔ Budget-friendly</p></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ScanningSystems;