import React, { Component } from 'react';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/switching_routing.png";
import image2 from "../../../../../../Assets/imgs/Sub_Solutions/it_router.png";
import image3 from "../../../../../../Assets/imgs/Sub_Solutions/wireless.jpg";

class ActiveNetworking extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="ActiveNetworking">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Active Networking</h2>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col">
              <p className="main-para">Though ‘fire extinguisher’ is the first name that probably comes to your mind when talking about fire-fighting, you must also be aware that a well-sought <span>fire hydrant system</span> and <span>sprinkler system</span> is equally responsible for eradicating fatal fire accidents. While <span>fire hydrant systems,</span> also known as <span>hydrant boosters</span> and <span>firewater pumps</span> are specialized high pressure water pumps curated to enhance the fire-fighting capacity of a building by releasing the pressure when mains fail, a <span>sprinkler system</span> is an active fire protection device which makes use of adequate pressure and flowrate through a water distribution piping system, to control fire. Cool and care blends all the goodness together, to design and manufacture hydrant systems as well as <span>sprinkler systems</span> that enhance fire safety at your home, workplace or elsewhere!</p>
            </div>
          </div> */}
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="Active Networking" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Switching &amp; Routing</h4>Sometimes, it may happen that your network is bogged down due to increased traffic and the number of devices trying to access it. In such cases, to have an effective communication system in your business environment, you need appropriately integrated service routers and local-area network switches. The network is the crux of your business IT operations. Not only does it move data within the local network but also routes the data in and out of the external network systems. Needless to say, your organization’s productivity depends greatly on a fast and secure network.</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para">A network router has picked pace on evolving from being a device dedicated to connect disparate networks to becoming an integrated service device which is multi-functional. It is a building block for leveraging your organization’s communications such as voice, video or wireless accessibility. On the other hand, Local Area Network switches form the core of all networks by providing high-speed connectivity, communication and application systems. One main concern is to transmit several bandwidth-intensive data, voice, video and wireless applications securely and effectively through proper networking; thus being able to provide for evolving traffic patterns, advanced services and optimized application performance.</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image2} alt="Active Networking" className="solutions-image-md" /></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image3} alt="Active Networking" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Wireless</h4>An increased use of multiple devices has been posing an escalating demand on WLAN’s, and therefore administrators are facing an ever-increasing challenge of providing extensive coverage on a growing group of devices. To cope with such intensive bandwidth-related issues, our team of experts put the best practices at work through design and deployment of wireless networks. Our wireless capabilities include antennas, location services outdoor mesh, intrusion prevention, along with ensuring our customers of a secured, reliable and optimum connectivity and performance.</p></div>
          </div>
          {/* <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para">We, at Capital, specialize in providing you with matchless solutions of Fire & Safety equipment which possess high-end capabilities of carrying out an extinguishing performance unerringly. Further, our <span>fire hydrant and sprinkler systems</span> reassure you of quality standards and higher durability.</p></div>
            <div className="col"></div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default ActiveNetworking;