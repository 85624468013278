import React, { Component } from 'react';
// import './HydrantSprinklers.scss';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/fire_hydrant.jpg";
import image2 from "../../../../../../Assets/imgs/Sub_Solutions/hydrant.jpg";
import image3 from "../../../../../../Assets/imgs/Sub_Solutions/Sprinkler.png";
// import image4 from "../../../../../../Assets/imgs/Sub_Solutions/co2.png";

class HydrantSprinklers extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="HydrantSprinklers">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Hydrant & Sprinkler</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="main-para">Though ‘fire extinguisher’ is the first name that probably comes to your mind when talking about fire-fighting, you must also be aware that a well-sought <span>fire hydrant system</span> and <span>sprinkler system</span> is equally responsible for eradicating fatal fire accidents. While <span>fire hydrant systems,</span> also known as <span>hydrant boosters</span> and <span>firewater pumps</span> are specialized high pressure water pumps curated to enhance the fire-fighting capacity of a building by releasing the pressure when mains fail, a <span>sprinkler system</span> is an active fire protection device which makes use of adequate pressure and flowrate through a water distribution piping system, to control fire. Cool and care blends all the goodness together, to design and manufacture hydrant systems as well as <span>sprinkler systems</span> that enhance fire safety at your home, workplace or elsewhere!</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="Hydrant Sprinklers" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Significance of Hydrant &amp; Sprinkler Systems</h4>Both hydrant and sprinkler systems have their own sets of benefits. However, the venue of installation and its type plays the deciding role as in which one to install between these two. We cater to your specific requirement and help securing your residential or commercial premise against ad hoc fire accidents.</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Benefits of a Hydrant System</h4><span>• Water Storage</span> - Fire hazards are a relentless worry in crowded areas. So, it is of utmost importance to keep a quickly accessible and local source of water ready. Fire hydrants fulfill that need by being a convenient source of water that you can make use of when it’s absolutely urgent.<br /><span>• Location and Access</span> - Fire hydrants are a part of a complex system that ensures complete coverage for fire protection to an area. The two major components of a fire hydrant are the water supply and a fire hose. So, the access is simple and alongside, it’s also located conveniently.</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image2} alt="Hydrant Sprinklers" className="solutions-image-md" /></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image3} alt="Hydrant Sprinklers" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Benefits of a Sprinkler System</h4><span>• Water saving</span> – A sprinkler system plays a key role in drip irrigation system, as it encourages more efficient water usage for irrigation in agriculture. These eliminate water conveyance channels, and reduce water loss. Water is also distributed more evenly in the field curtailing wastage, and leading to increased yield.<br /><span>• Easy Usage</span> - Water sprinklers are very easy to use and highly effective.<br /><span>• Effective Fire Fighting</span> - Automated sprinkler systems work well to control fire rapidly, without requiring any sort of human intervention. Also, it reduces needs of manual security and minimizes scope of intrusion.</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para">We, at Capital, specialize in providing you with matchless solutions of Fire & Safety equipment which possess high-end capabilities of carrying out an extinguishing performance unerringly. Further, our <span>fire hydrant and sprinkler systems</span> reassure you of quality standards and higher durability.</p></div>
            <div className="col"></div>
          </div>
        </div>

      </div>
    );
  }
}

export default HydrantSprinklers;