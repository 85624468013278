import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollTop from "./ScrollToTop";
import Burger from "./Core/NavBar/BurgerMenu";
import "./Core/NavBar/BurgerMenu.scss";
// import './Core/NavBar'
import NavBar from './Core/NavBar';
import CarouselPanel from './Core/CarouselPanel';
import Cards from './Core/Cards/Cards';
import ServiceWidget from './Core/ServicesWidget/ServicesWidget';
import ConsultationWidget from './Core/ConsultationWidget/ConsultationWidget';
import Footer from './Core/Footer/Footer';
// Assets Import
import BackgroundRing from './Assets/imgs/bg-ring.png';
import CapitalLogo from './Assets/imgs/capital_logo.png';
// Page Imports
import CompanyPage from './Core/Pages/Company';
import ProductsPage from './Core/Pages/Products';
import BusinessAssociatesPage from './Core/Pages/BusinessAssociates';
import ContactUsPage from './Core/Pages/ContactUs';
import ErrorPage from './Core/Pages/ErrorPage';
// Solutions Import
import Solutions from './Core/Pages/Solutions/SolutionsTemplate';
// SubSolutions Imports
import FireExtinguishers from './Core/Pages/Solutions/SubSolutions/FireSafety/FireExtinguishers';
import HydrantSprinklers from './Core/Pages/Solutions/SubSolutions/FireSafety/HydrantSprinklers';
import FloodingSystems from './Core/Pages/Solutions/SubSolutions/FireSafety/FloodingSystems';
import SpecializedExtinguishers from './Core/Pages/Solutions/SubSolutions/FireSafety/SpecializedExtinguishers';
import Enterprise from './Core/Pages/Solutions/SubSolutions/InformationCommunication/Enterprise';
import ActiveNetworking from './Core/Pages/Solutions/SubSolutions/InformationCommunication/ActiveNetworking';
import PassiveNetworking from './Core/Pages/Solutions/SubSolutions/InformationCommunication/PassiveNetworking';
import Power from './Core/Pages/Solutions/SubSolutions/InformationCommunication/Power';
import DataCenter from './Core/Pages/Solutions/SubSolutions/InformationCommunication/DataCenter';
import NetworkSecurity from './Core/Pages/Solutions/SubSolutions/ITSecurity/NetworkSecurity';
import VideoSurveillance from './Core/Pages/Solutions/SubSolutions/PhysicalSecurity/VideoSurveillance';
import AlarmSystems from './Core/Pages/Solutions/SubSolutions/PhysicalSecurity/AlarmSystems';
import AccessControl from './Core/Pages/Solutions/SubSolutions/PhysicalSecurity/AccessControl';
import EntranceManagement from './Core/Pages/Solutions/SubSolutions/PhysicalSecurity/EntranceManagement';
import ScanningSystems from './Core/Pages/Solutions/SubSolutions/PhysicalSecurity/ScanningSystems';
import PreSales from './Core/Pages/Solutions/SubSolutions/Services/PreSales';
import PostSales from './Core/Pages/Solutions/SubSolutions/Services/PostSales';
import AnnualMaintenance from './Core/Pages/Solutions/SubSolutions/Services/AnnualMaintenance';
// import OSDatabase from './Core/Pages/Solutions/SubSolutions/Software/OSDatabase';
// import Applications from './Core/Pages/Solutions/SubSolutions/Software/Applications';


// import Company from './Core/Company/Company';
// import Switch from 'react-bootstrap/esm/Switch';

function App() {
  return (
    <Router>
    <div className="app">
      <Burger pageWrapId={"page-wrap"} outerContainerId={"App"} width={ '80px' }/>
      <div className="page-wrap">
        <NavBar />
        <ScrollTop />
        <Switch>
          <Route path="/solutions/fire-safety-precaution">
            <Solutions value="Fire, Safety &amp; Precaution" flowChart="/flow_charts/fire.svg" chartStyle="fire__chart" />
          </Route>
          <Route path="/solutions/information-communication-technology">
            <Solutions value="Information &amp; Communication Technology" flowChart="/flow_charts/itinfra.svg" chartStyle="information-technology__chart" />
          </Route>
          <Route path="/solutions/IT-Security">
            <Solutions value="IT Security" flowChart="/flow_charts/itsecurity.svg" chartStyle="it-security__chart" />
          </Route>
          <Route path="/solutions/physical-security">
            <Solutions value="Physical Security" flowChart="/flow_charts/physical.svg" chartStyle="physical-security__chart" />
          </Route>
          <Route path="/solutions/services">
            <Solutions value="Services" flowChart="/flow_charts/services.svg" chartStyle="services__chart" />
          </Route>
          {/* Solutions Sub Menu */}
          <Route path="/solutions/extinguishers">
            <FireExtinguishers />
          </Route>
          <Route path="/solutions/hydrant-sprinklers">
            <HydrantSprinklers />
          </Route>
          <Route path="/solutions/total-flooding-systems">
            <FloodingSystems />
          </Route>
          <Route path="/solutions/specialized-stand-alone-extinguishers">
            <SpecializedExtinguishers />
          </Route>
          <Route path="/solutions/enterprise">
            <Enterprise />
          </Route>
          <Route path="/solutions/active-networking">
            <ActiveNetworking />
          </Route>
          <Route path="/solutions/passive-networking">
            <PassiveNetworking />
          </Route>
          <Route path="/solutions/power">
            <Power />
          </Route>
          <Route path="/solutions/data-center">
            <DataCenter />
          </Route>
          <Route path="/solutions/network-security">
            <NetworkSecurity />
          </Route>
          <Route path="/solutions/video-survillance">
            <VideoSurveillance />
          </Route>
          <Route path="/solutions/alarm-systems">
            <AlarmSystems />
          </Route>
          <Route path="/solutions/access-control">
            <AccessControl />
          </Route>
          <Route path="/solutions/entrance-management-systems">
            <EntranceManagement />
          </Route>
          <Route path="/solutions/scanning-systems">
            <ScanningSystems />
          </Route>
          <Route path="/solutions/pre-sales">
            <PreSales />
          </Route>
          <Route path="/solutions/implementation">
            <PostSales />
          </Route>
          <Route path="/solutions/annual-maintenance-contracts">
            <AnnualMaintenance />
          </Route>
          {/* Main Nav */}
          <Route path="/company">
            <CompanyPage />
          </Route>
          <Route path="/products">
            <ProductsPage />
          </Route>
          <Route path="/business-associates">
            <BusinessAssociatesPage />
          </Route>
          <Route path="/contact-us">
            <ContactUsPage />
          </Route>
          <Route path="/support">
            <h1>Support</h1>
          </Route>
          <Route path="/">
            <CarouselPanel />
            <Cards />
            {/* <section className="about">
              <div className="about-image">
              <img className="company-logo" src={CapitalLogo} alt="Website Logo" />
              <div className="animated-img">
              <img className="rotate-me" src={BackgroundRing} alt="Background Ring" />
              </div>
              </div>
              <div className="about-text">
              <p>We at Capital Information Technology Systems help our customers and their businesses stay agile and super-effective in the global marketplace with the help of our robust IT Infrastructure, Security, Fire Protection, Software, Smart Home, and Office Automation & BMS (Building Management System) Solutions, making them feel and experience our support at every step of their success.</p>
              </div>
            </section> */}
            <ServiceWidget />
            <ConsultationWidget heading="Request a Free Consultation" showSelect={true}/>
          </Route>
          {/* <Route path="*" component={ErrorPage} />
          <h1>!! 404 - Page not found!!</h1>
            <ErrorPage show="false" />
          </Route> */}
        </Switch>
        <Footer />
      </div>
    </div>
    </Router>
  );
}

export default App;