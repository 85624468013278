import React, { Component } from "react";
import "./CardWidget.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapMarkerAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
class CardWidget extends Component {
  // constructor(props){
  // super(props);
  // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="card-widget">
        <section className="container">
          <div className="row">
              <div className="section-heading">
                <h2>{this.props.heading}</h2>
              </div>
          </div>
          <div className="row">
            <p className="section-content">{this.props.content}</p>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-lg-4">
              <div className="card-box">
                <h5><span><FontAwesomeIcon icon={faEnvelope} style={{ color: '#40cd6c' }}  size="md"/></span>Mail Here</h5>
                <p><a href="mailto:info@capital.net.sa">info@capital.net.sa</a></p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-4">
              <div className="card-box">
                <h5><span><FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: '#40cd6c' }} size="lg" /></span> Visit Here</h5>
                <p>Prince Badr Street, Cross-A, Al-Khobar 31952, K.S.A</p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-4">
              <div className="card-box">
                <h5><span><FontAwesomeIcon icon={faPhoneAlt} style={{ color: '#40cd6c' }} size="md" /></span> Call Here</h5>
                <p><a href="tel:+96638960890">+(966)13-896-0890</a></p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CardWidget;
