import React, { Component } from 'react';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/datacenter.jpg";
import image2 from "../../../../../../Assets/imgs/Sub_Solutions/storage.jpg";
import image3 from "../../../../../../Assets/imgs/Sub_Solutions/data_center_cooling.jpg"; 

class DataCenter extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="DataCenter">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Data Center</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="Data Center" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Design &amp; Build</h4>The relevance of a solid foundation in the physical design and build of your data center must not be compromised – more so because it lays the base of supporting your critical network, compute and storage functions. We, at Capital, offer you the best practices and expert industry knowledge to cater to your specific IT related requirements. From designing data centers that fit your exact needs to delving in depth about understanding how your infrastructure must operate and communicate, we provide end-to-end data center solutions at lucrative prices.<br />Our detailed CAD drawings and construction documentation shall help you get a clearer insight of how the finished product is going to look like, thereby enabling suppliers to prepare exact quotations and eliminating scopes of change orders.</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para">Our data managers armed with the latest data center certifications are focused at meeting your expectation in due time, and within your budget. Our build management methodologies are rolled out with absolute transparency wherein all stakeholders are informed about the progress and project milestones. Further, the deep domain knowledge of our team members enables us to support your business in all aspects of white space build-out, viz. power distribution, floor layouts, cooling efficiency, etc.<br />One of our core specializations lies in creating data centers that are adaptable to future up gradations. We know that requirements change over time, so we make it a point to keep molecularity and flexibility in mind so as to ensure that your facility is functional throughout its lifespan. We shall also provide you with accurate details of the as-built data center such that your team has a reliable source of reference for maintenance in the future. In a nutshell, Capital ensures you a timely completion of the design and build of your data center, with a keen attention to detail and extensive review process.</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image2} alt="Data Center" className="solutions-image-md" /></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image3} alt="Data Center" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>DC Environment</h4><strong>Precision Air Cooling</strong><br/>Capital provides for practical, optimized and innovative methodologies for data center cooling at affordable costs. We develop customized air conditioning and temperature control forms to cater to unique cooling system needs of contemporary data centers. We also verify its performance levels and reliability right at the time of engineering development and designing process so as to doubly ensure high-scale performance.<br /><strong>Raised Floors</strong><br/>Implementing Capital’s raised floors in your data center shall improve your operational efficiency by allowing a greater flexibility of equipment, optimizing available space between two floors to supply cooling air to the equipment or area, protecting power receptacles, making way for future layout changes and much more!<br /><strong>Sensors</strong><br />If you are looking for cost-effective, modern-day sensors best fitted for your contemporary data center needs, such as environmental monitoring, alert generation due to smoke, water or open cabinet doors, it’s time to get in touch with us!</p></div>
          </div>
        </div>
      </div>
    );
  }
}

export default DataCenter;