import React, { Component } from "react";
import "./ServicesWidget.scss";
import ServiceLogo from '../../Assets/imgs/service_logo_new.png'
import Service1 from '../../Assets/imgs/service-w1.png'
import Service2 from '../../Assets/imgs/service-w2.png'
import Service3 from '../../Assets/imgs/service-w3.png'
import Service4 from '../../Assets/imgs/service-w4.png'

class ServicesWidget extends Component {
  // constructor(props){
  // super(props);
  // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="services-widget">
        {/* <h2 className="section-head"><u>Services & Solutions</u></h2> */}
        <div className="container bg-service-solution">
          <div className="row">
            <div className="col-xs-12 automargin">
              <div className="section-heading text-center">
                <h2>Services &amp; Solutions</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3">
              <div className="center-img">
                <img
                  src={ServiceLogo}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 service-bg-h">
              <div className="service-box-home">
                <div className="service-box-border">
                  <h3>Information &amp; Communiaction Technology</h3>
                  <img
                    src={Service1}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6 service-bg-h">
              <div className="service-box-home">
                <div className="service-box-border text-right">
                  <h3>Fire Safety and protection</h3>
                  <img
                    src={Service2}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6 service-bg-h">
              <div className="service-box-home">
                <div className="service-box-border text-right">
                  <h3>IT Securities</h3>
                  <img
                    src={Service3}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6 service-bg-h">
              <div className="service-box-home">
                <div className="service-box-border text-right">
                  <h3>Physical Security</h3>
                  <img
                    src={Service4}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-xs-12">
          <div className="section-heading text-center">
            <h2>Industry Served</h2>
          </div>
        </div> */}
      </div>
    );
  }
}

export default ServicesWidget;
