import React, { Component } from "react";
import emailjs from 'emailjs-com';
import "./ConsultationWidget.scss";
import CircleShape from "../../Assets/imgs/circle-shape.png";
import ConsultationVector from "../../Assets/imgs/consultation-img.png";

class ConsultationWidget extends Component {
  // constructor(props){
  // super(props);
  // this.state = {
  //   disabled: 'true',
  // };
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  // Development Configuration
  // sendEmail(e) {
  //   e.preventDefault();
  //   emailjs.sendForm("service_ga9pwzg", "template_1c6p6wq", e.target, "user_khuAxzB8ridINeupDP9GT")
  //     .then((result) => {
  //         // console.log(result.text);
  //         alert("Your message was successfully sent!");
  //         // document.getElementsByClassName("contact-form").reset();
  //       }, (error) => {
  //         alert("There was an error! Please try again later.");
  //         // console.log("error.text");
  //       });
  //       e.target.reset()
  // }

  // Production Configuration
  sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('service_7dwwdod', 'template_tptlajf', e.target, 'user_yA6haTnyI2vK68qvYqXox')
      .then((result) => {
          // console.log(result.text);
          alert("Your message was successfully sent!");
      }, (error) => {
          alert("There was an error! Please try again later.");
          // console.log(error.text);
      });
      e.target.reset()      //Resets the Form
  }

  render() {
    // const disabled = true;
    return (
      <div id="consultation-widget">
        <div className="col-xs-12">
          <div className="section-heading text-center">
            <h2>{this.props.heading}</h2>
          </div>
          <div className="heading-para">
            <p>We want you to get involved with the technology! Would you like to heighten yourself up even more with the help of technological solutions and services that we are offering to our customers? Well, that’s easy! Fill in the form and required details and we will take an insightful view over your requirement to figure out how effective as well as significantly superb you can be with the help of our breakthrough solutions at large!</p>
          </div>
        </div>
        <div className="no-margin row">
          <div className="col-xs-12 col-sm-6">
            <div className="consultation-img">
              <img src={ConsultationVector} className="img-responsive" alt="" />
              <div className="animated-img">
                <img
                  src={CircleShape}
                  className="img-responsive bg-round-shape rotateme"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6">
            <form className="contact-form" ref={form => this.form = form} onSubmit={this.sendEmail} >
              <input className="form-control" type="text" placeholder="Name*" name="from_name"  required />
              <input className="form-control" type="text" placeholder="Email*" name="reply_to"  required />
              <input className="form-control" type="text" placeholder="Phone*" name="phone_number"  required />
              {this.props.showSelect ? 
                <select className="form-control" type="text" name="product" required >
                <option value="" selected disabled>Select Product*</option>
                <option value="Fire, Safety &amp; Precaution">Fire, Safety &amp; Precaution</option>
                <option value="Information &amp; Communication Technology">Information &amp; Communication Technology</option>
                <option value="IT Security">IT Security</option>
                <option value="Physical Security">Physical Security</option>
                <option value="Services">Services</option>
              </select>
              : null}
              {this.props.showProduct ?
                <input className="form-control" type="text" value={this.props.value} name="product"  readOnly />
              : null}
              {this.props.showSubject ?  
                <input className="form-control" type="text" placeholder="Subject*" name="subject"  required />
              : null}
              <textarea className="form-control" placeholder="Your Message*" name="message"  required></textarea>
              <input type="submit" value="Send Message" className="btn-send" /> 
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ConsultationWidget;