import React, { Component } from 'react';
import './SolutionsTemplate.scss';
import ConsultationWidget from "../../../../Core/ConsultationWidget/ConsultationWidget";
import PageHeader from "../../../../Assets/imgs/sub_solution.jpg";

class SolutionsTemplate extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="SolutionsTemplate">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="chart-container">
          <img src={this.props.flowChart} className={`flow-chart ${this.props.chartStyle}`} alt="Solution Chart" />
        </div>
        <ConsultationWidget heading="Request a Free Consultation" showProduct={true} value={this.props.value}/>
      </div>
    );
  }
}

export default SolutionsTemplate;