import React, { Component } from "react";
import "./CarouselPanel.scss";
import Carousel from 'react-bootstrap/Carousel'
import backdrop1 from "../../Assets/imgs/back_green.jpg";
import backdrop2 from "../../Assets/imgs/back_blue.png";
import backdrop3 from "../../Assets/imgs/back_purple.jpg";
import image1 from "../../Assets/imgs/security-image.png";
import image2 from "../../Assets/imgs/data-center.png";
import image3 from "../../Assets/imgs/computer-image.png";

class CarouselPanel extends Component {
  // constructor(props){
  // super(props);
  // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="carousel-panel">
        <Carousel className="image-set mui-fixed" pause={false} interval={7000}>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={backdrop1}
              alt="First slide"
            />
            <img
              className="sub-image"
              src={image1}
              alt="First slide"

            />
            <Carousel.Caption>
              <h3>Technology Driven <br /> Fire &amp; Safety Solutions</h3>
              <p>We Make You Agile &amp; Responsive<br />To Prevent Worst Cases From Happening!</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={backdrop2}
              alt="Third slide"
            />
            <img
              className="sub-image"
              src={image2}
              alt="First slide"

            />
            <Carousel.Caption>
              <h3>Robust &amp; Rewarding<br /> Information Technology <br /> Infrastructure</h3>
              <p>Delivering Sophisticated &amp; Custom-Made I.T. Solutions<br /> That Fit Businesses’ Requirement!</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={backdrop3}
              alt="Third slide"
            />
            <img
              className="sub-image"
              src={image3}
              alt="First slide"

            />
            <Carousel.Caption>
              <h3>Mordern Physical<br />Security Solutions</h3>
              <p>Security measures that will never let you down.</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}

export default CarouselPanel;
