import React, { Component } from 'react';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/postsales.webp";

class PostSales extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="PostSales">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Post Sales</h2>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col">
              <p className="main-para">Though ‘fire extinguisher’ is the first name that probably comes to your mind when talking about fire-fighting, you must also be aware that a well-sought <span>fire hydrant system</span> and <span>sprinkler system</span> is equally responsible for eradicating fatal fire accidents. While <span>fire hydrant systems,</span> also known as <span>hydrant boosters</span> and <span>firewater pumps</span> are specialized high pressure water pumps curated to enhance the fire-fighting capacity of a building by releasing the pressure when mains fail, a <span>sprinkler system</span> is an active fire protection device which makes use of adequate pressure and flowrate through a water distribution piping system, to control fire. Cool and care blends all the goodness together, to design and manufacture hydrant systems as well as <span>sprinkler systems</span> that enhance fire safety at your home, workplace or elsewhere!</p>
            </div>
          </div> */}
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Implementation services</h4>Once a solution is designed and a proposal is approved our team of experts can start implementing the necessary changes. Our products are installed in a timely manner to assure your business can benefit from new technology as soon as possible. By understanding your business challenges, conducting a thorough site survey and matching suitable technology we are able to implement the proposed solution in seamless manner.</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="Post Sales" className="solutions-image-md" /></div>
          </div>
        </div>
      </div>
    );
  }
}

export default PostSales;