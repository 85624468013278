import React, { Component } from 'react';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/maintenance.jpg";
import image2 from "../../../../../../Assets/imgs/Sub_Solutions/survey.svg";
import image3 from "../../../../../../Assets/imgs/Sub_Solutions/security.png";

class AnnualMaintenance extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="AnnualMaintenance">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Annual Maintenance Contracts</h2>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col">
              <p className="main-para">Though ‘fire extinguisher’ is the first name that probably comes to your mind when talking about fire-fighting, you must also be aware that a well-sought <span>fire hydrant system</span> and <span>sprinkler system</span> is equally responsible for eradicating fatal fire accidents. While <span>fire hydrant systems,</span> also known as <span>hydrant boosters</span> and <span>firewater pumps</span> are specialized high pressure water pumps curated to enhance the fire-fighting capacity of a building by releasing the pressure when mains fail, a <span>sprinkler system</span> is an active fire protection device which makes use of adequate pressure and flowrate through a water distribution piping system, to control fire. Cool and care blends all the goodness together, to design and manufacture hydrant systems as well as <span>sprinkler systems</span> that enhance fire safety at your home, workplace or elsewhere!</p>
            </div>
          </div> */}
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Maintenance based on our proposals</h4>As part of our after sales service, we offer your organization an annual maintenance, support and service contract based on our proposals so as to ensure that your business’ computer systems and network at their peak performance levels. Our service contract encompasses of regular scheduled maintenance for all your business systems and equipment in lieu of a mutually agreed service level and cost.</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="Maintenace Process" className="solutions-image-md" /></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image2} alt="Surveys" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Survey existing technology and create unique contracts</h4>Our expert team of technicians conduct insightful surveys on the existing technologies used in your business environment. Having identified your unique business context from the survey, we also thrive to offer you a tailor-made maintenance contract based on your specific needs. We assure you 100% transparency while creating unique contracts, in line with your business goals and overall business performance.</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>At Capital, we provide you with the following types of AMC services</h4>
              <ul>
                <li><strong>IT and Security</strong> - We ensure keeping businesses thriving with managed IT and security maintenance services. Our team of expert technicians conduct regular pro-active maintenance of your computer and server systems, thereafter saving you from serious downtime issues. Apart from catering to your IT maintenance requirements, we also conduct annual assessments to carry out a comprehensive review of your CCTV systems, and doubly ensure the safety of your workplace or residential premise. Keeping quality parameters at the forefront and conducting a thorough health-check across your IT environment, we strive to handle all your technology updates, antivirus, security and monitoring systems.</li>
                <li><strong>Fire Safety</strong> - We conduct routine inspections and maintenance of the existing fire safety systems in your commercial, industrial and residential premise, and recommend additions or upgradations based on its suitability. Your fire systems are inspected, tested and serviced by our expert team of professionals so as to ensure that they deliver optimum performance when called upon. Not only do we run an initial assessment of your fire risk, we also design an effective and affordable solution through to install, test, commission, and thereafter maintain the final system.</li>
                <li><strong>AC</strong> - We conduct routine inspections and maintenance of the existing fire safety systems in your commercial, industrial and residential premise, and recommend additions or upgradations based on its suitability. Your fire systems are inspected, tested and serviced by our expert team of professionals so as to ensure that they deliver optimum performance when called upon. Not only do we run an initial assessment of your fire risk, we also design an effective and affordable solution through to install, test, commission, and thereafter maintain the final system.</li>
              </ul>
            </p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image3} alt="Security Systems" className="solutions-image-md" /></div>
          </div>
        </div>
      </div>
    );
  }
}

export default AnnualMaintenance;