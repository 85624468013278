import React from "react";
import "./NavBar.scss";
import { Link, NavLink } from "react-router-dom";
import SmallLogoWhite from '../../Assets/imgs/capital_logo_white2.png';
import Menu from 'material-ui-popup-state/HoverMenu';
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from '@material-ui/core/styles/withStyles';
import {
  usePopupState,
  bindHover,
  bindMenu,
} from 'material-ui-popup-state/hooks';

const ParentPopupState = React.createContext(null)

const NavBar = () => {
  
  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  const popupState = usePopupState({ popupId: 'demoMenu', variant: 'popover' })
    
    return (
      <div className="container" id="nav">
        <div className="row">
          <div className="nav-bar mui-fixed">
            <div className="logo-container">
              <NavLink exact to="/" activeClassName="selected"><img src={SmallLogoWhite} alt="Website Logo" /></NavLink>
            </div>
            <div className="nav-menu">
              <ul className="nav-links">
                <li><NavLink exact to="/" activeClassName="selected">HOME</NavLink></li>
                <li><NavLink to="/company" activeClassName="selected">COMPANY</NavLink></li>
                <li><NavLink to="/products" activeClassName="selected">PRODUCTS</NavLink></li>
                <li><NavLink to="#" {...bindHover(popupState)}>SOLUTIONS</NavLink></li>
                <li><NavLink to="/business-associates" activeClassName="selected">BUSINESS ASSOCIATES</NavLink></li>
                <li><NavLink to="/contact-us" activeClassName="selected">CONTACT</NavLink></li>
              </ul>
            </div>
            <ParentPopupState.Provider value={popupState}>
            <Menu
              {...bindMenu(popupState)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              getContentAnchorEl={null}
            >
            {/* <Link to="/solutions/cooling-lighting-automation" className="menu-links" onClick={popupState.close}><Submenu popupId="choicesMenu" title="Cooling, Lighting & Automation">
              <Link to="/solutions/cooling"><MenuItem onClick={popupState.close}>Cooling</MenuItem></Link>
              <Link to="/solutions/lighting"><MenuItem onClick={popupState.close}>Lighting</MenuItem></Link>
              <Link to="/solutions/automation"><MenuItem onClick={popupState.close}>Automation</MenuItem></Link>
            </Submenu></Link> */}
            <Link to="/solutions/fire-safety-precaution" className="menu-links" onClick={popupState.close}><Submenu popupId="choicesMenu" title="Fire, Safety &amp; Precaution">
              <Link to="/solutions/extinguishers"><MenuItem onClick={popupState.close}>Fire extinguisher &amp; standalone extinguisher</MenuItem></Link>
              <Link to="/solutions/hydrant-sprinklers"><MenuItem onClick={popupState.close}>Hydrant &amp; Sprinkler</MenuItem></Link>
              <Link to="/solutions/total-flooding-systems"><MenuItem onClick={popupState.close}>Total Flooding Systems</MenuItem></Link>
              <Link to="/solutions/specialized-stand-alone-extinguishers"><MenuItem onClick={popupState.close}>Specialized Stand Alone Extinguisher</MenuItem></Link>
            </Submenu></Link>
            <Link to="/solutions/information-communication-technology" className="menu-links" onClick={popupState.close}><Submenu popupId="choicesMenu" title="Information &amp; Communication Technology">
              <Link to="/solutions/enterprise"><MenuItem onClick={popupState.close}>Enterprise</MenuItem></Link>
              <Link to="/solutions/active-networking"><MenuItem onClick={popupState.close}>Active Networking</MenuItem></Link>
              <Link to="/solutions/passive-networking"><MenuItem onClick={popupState.close}>Passive Networking</MenuItem></Link>
              <Link to="/solutions/power"><MenuItem onClick={popupState.close}>Power</MenuItem></Link>
              <Link to="/solutions/data-center"><MenuItem onClick={popupState.close}>Data Center</MenuItem></Link>
            </Submenu></Link>
            <Link to="/solutions/IT-Security" className="menu-links" onClick={popupState.close}><Submenu popupId="choicesMenu" title="IT Security">
              <Link to="/solutions/network-security"><MenuItem onClick={popupState.close}>Network Security</MenuItem></Link>
            </Submenu></Link>
            <Link to="/solutions/physical-security" className="menu-links" onClick={popupState.close}><Submenu popupId="choicesMenu" title="Physical Security">
              <Link to="/solutions/video-survillance"><MenuItem onClick={popupState.close}>Video Surveillance</MenuItem></Link>
              <Link to="/solutions/alarm-systems"><MenuItem onClick={popupState.close}>Alarm Systems</MenuItem></Link>
              <Link to="/solutions/access-control"><MenuItem onClick={popupState.close}>Access Control</MenuItem></Link>
              <Link to="/solutions/entrance-management-systems"><MenuItem onClick={popupState.close}>Entrance Management Systems</MenuItem></Link>
              <Link to="/solutions/scanning-systems"><MenuItem onClick={popupState.close}>Scanning Systems</MenuItem></Link>
            </Submenu></Link>
            <Link to="/solutions/services" className="menu-links" onClick={popupState.close}><Submenu popupId="choicesMenu" title="Services">
              <Link to="/solutions/pre-sales"><MenuItem onClick={popupState.close}>Pre-Sales</MenuItem></Link>
              <Link to="/solutions/implementation"><MenuItem onClick={popupState.close}>Post-Sales/Implementation</MenuItem></Link>
              <Link to="/solutions/annual-maintenance-contracts"><MenuItem onClick={popupState.close}>Annual Maintenance Contracts</MenuItem></Link>
            </Submenu></Link>
          </Menu>
          </ParentPopupState.Provider>
        </div>
      </div>
    </div>
    );
  }


export default NavBar;

const submenuStyles = theme => ({
  menu: {
    marginTop: theme.spacing(-1),
  },
  title: {
    flexGrow: 1,
  },
  moreArrow: {
    marginRight: theme.spacing(-1),
  },
})

const Submenu = withStyles(submenuStyles)(
  // Unfortunately, MUI <Menu> injects refs into its children, which causes a
  // warning in some cases unless we use forwardRef here.
  React.forwardRef(({ classes, title, popupId, children, ...props }, ref) => {
    const parentPopupState = React.useContext(ParentPopupState)
    const popupState = usePopupState({
      popupId,
      variant: 'popover',
      parentPopupState,
    })
    return (
      <ParentPopupState.Provider value={popupState}>
        <MenuItem
          {...bindHover(popupState)}
          selected={popupState.isOpen}
          ref={ref}
        >
          <span className={classes.title}>{title}</span>
          {/* <ChevronRight className={classes.moreArrow} /> */}
        </MenuItem>
        <Menu
          {...bindMenu(popupState)}
          classes={{ paper: classes.menu }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
          {...props}
        >
          {children}
        </Menu>
      </ParentPopupState.Provider>
    )
  })
)