import React, { Component } from 'react';
// import './FloodingSystems.scss';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/flooding.jpg";
import image2 from "../../../../../../Assets/imgs/Sub_Solutions/tube_based.jpg";
import image3 from "../../../../../../Assets/imgs/Sub_Solutions/Sprinkler.png";

class FloodingSystems extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="FloodingSystems">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Total Flooding Systems</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="main-para">Total flooding systems are most suitable to be used in enclosed places - rooms, ovens, machines to name a few and also, at places containing materials extinguishable by carbon dioxide. The most important criterion for effective total flooding is that the space has to be reasonably well enclosed. Openings should be made to shut automatically and the same should go for ventilation equipment, strictly before the beginning of discharge. Else, excess of carbon dioxide supply must be provided to make up for the leakage. Types of Total Flooding Systems Cool and Care has housed a number of products for effective total flooding, such as a gas suppression system, water mister/spray system and tube based suppression system.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="Flooding Systems" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Gas Suppression System</h4>The fire protection gas suppression system or commonly termed as automatic fire suppression system housed by Capital makes use of the cutting edge technology for its premium design and manufacture. It excels over the conventional system which uses water. In gas based fire suppression system water is replaced by gas to serve the purpose of fire extinguishing. The activation process is entirely automated and controls the fire within a minute without any kind of human participation.</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Tube Based Suppression System</h4>The Tube Based Fire Suppression System is basically a simple automated system that has in store the widest versatility that leads to an outstanding cost to performance balance. The ones from the house of Capital ensures the fire is stopped right at its source and provides ultimate safety to your place, as it uses linear detection tubing installed throughout & can quickly detect fire with the highest accuracy and suppress it before it causes any damage.</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image2} alt="Flooding Systems" className="solutions-image-md" /></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image3} alt="Flooding Systems" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Water Mister/ Spray System</h4>A water mister or spray system plays a key role in drip irrigation. Using this, water forced through the irrigation system is diffused followed by distribution of small water droplets across the place. Water misters ease up a gardener’s or farmer’s work as well as save a significant volume of water along with covering a wider area than what traditional irrigation generally does. Capital specializes in designing and manufacturing optimum quality misting systems for Residential, Industrial, Commercial, and Agriculture requirements.</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para">So, if you are looking for a fire safety solution to make your space score high on security factor every time, your search ends right here! Get your hands on the Capital total flooding system where experience meets innovation, now!</p></div>
            <div className="col"></div>
          </div>
        </div>

      </div>
    );
  }
}

export default FloodingSystems;