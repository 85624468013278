import React, { Component } from 'react';
import '../../SubSolutions.scss';
import PageHeader from "../../../../../../Assets/imgs/sub_solution.jpg";
import image1 from "../../../../../../Assets/imgs/Sub_Solutions/access_control.jpg";
import image2 from "../../../../../../Assets/imgs/Sub_Solutions/access_types.jpg";
import image3 from "../../../../../../Assets/imgs/Sub_Solutions/biometrics.jpg";
import image4 from "../../../../../../Assets/imgs/Sub_Solutions/facial_recog.jpg";
import image5 from "../../../../../../Assets/imgs/Sub_Solutions/proximity_card.jpg";
import image6 from "../../../../../../Assets/imgs/Sub_Solutions/time_attendance.jpg";

class AccessControl extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div id="AccessControl">
        <img src={PageHeader} className="page-header" alt="PageHeader" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-heading">
                <h2>Access Control</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="main-para">The demand for a flexible workspace has increased significantly. The term ‘flexible workspace’ basically refers to a modern space equipped with cutting edge technology and most importantly, an effective access control system to ensure restricted yet swift and seamless access. There are a number of access control techniques readily available and Capital can help you find the one that best fits your business.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image1} alt="Access Control Systems" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Types of access control systems to upgrade your workspace!</h4>We hold a firm grip on access control and time attendance management systems. This includes world class products like biometric attendance systems for education centers or corporate space. Uses of this system include: utlizing attendance access control, incorporating facial recognition as a method of access control for devices, data and even, the entire workspace; and also proximity cards for door access control.</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Which access control system to choose &amp; why?</h4>Among the various access control systems curated to serve varied purposes, it is extremely important to know all your options before choosing the one that suits your workspace. Some of the different access control systems offered by Capital (along with their functions) include:</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image2} alt="Access Control Systems" className="solutions-image-md" /></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image3} alt="Access Control Systems" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Biometric Access Control</h4>This system primarily makes use of biometric identification that enables the identification procedure based on an individual’s biological qualities. Its advantages include,<br />✔ Added security<br />✔ Low maintenance cost<br />✔ No need of additional devices for opening the door</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Facial Recognition Access Control</h4>This system is like a more specialized version of biometrics that uses facial features for identification. The benefits include<br />✔ Higher level of security<br />✔ Easy integration<br />✔ High accuracy<br />✔ Full automation</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image4} alt="Access Control Systems" className="solutions-image-md" /></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><img src={image5} alt="Access Control Systems" className="solutions-image-md" /></div>
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Proximity Card</h4>This is a contactless smart card aiming to achieve a higher level of security of the credential and the entire access-control system. The technology is optimized to perform cryptography, encryption, and utilizes strong internal computing abilities. Advantages of this smart card are:<br />✔ Customized definition and access control<br />✔ Advanced technology enables flexibility for future-proofing<br />✔ Cost effective</p></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 content"><p className="sub-para"><h4>Time &amp; Attendance</h4>Time and attendance access control is attained combining the best of the aforementioned technologies to ensure the highest level of security. This is most suitable for the places where the workspace is accessible to a fixed set of employees/ students and the attendance and time needs to be noted and stored.<br />✔ Efficient storage<br />✔ Accurate record</p></div>
            <div className="col-xs-12 col-md-6 content"><img src={image6} alt="Access Control Systems" className="solutions-image-md" /></div>
          </div>
          
        </div>
      </div>
    );
  }
}

export default AccessControl;