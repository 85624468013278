import React, { Component } from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import ShapeTriangle from '../../Assets/imgs/shape2.png'
import ShapeSquare from '../../Assets/imgs/shape6.png'
import ShapeCross from '../../Assets/imgs/shape4.png'
import SmallLogo from '../../Assets/imgs/capital_logo2.png'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faEnvelope, faMapMarkerAlt, faCube } from "@fortawesome/free-solid-svg-icons";

class Footer extends Component {
  // constructor(props){
  // super(props);
  // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    const col1  = [
      {to: "/", label: "Home"},
      {to: "/company", label: "Company"},
      {to: "/products", label: "Products"},
      {to: "/business-associates", label: "Business Associates"},
      {to: "/contact-us", label: "Contact"}
    ]
    const col2  = [
      {to: "/solutions/fire-safety-precaution", label: "Fire, Safety & Precaution"},
      {to: "/solutions/information-communication-technology", label: "Information & Communication Technology"},
      {to: "/solutions/IT-Security", label: "IT Security"},
      {to: "/solutions/physical-security", label: "Physical Security"},
      {to: "/solutions/services", label: "Services"}
    ]
    return (
      <div id="footer" >
        <div className="container-fluid bg-footer">
          <div className="animate-shape1">
            <img
              src={ShapeTriangle}
              className="rotateme"
              alt="Shape 1"
            />
          </div>
          <div className="animate-shape2">
            <img
              src={ShapeSquare}
              className="rotatemeanticlock"
              alt="Shape 2"
            />
          </div>
          <div className="animate-shape3">
            <img
              src={ShapeCross}
              className="rotateme"
              alt="Shape 3"
            />
          </div>
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-8 col-lg-3">
                  <div className="footer-about-div">
                    <div className="row">
                    <img
                      src={SmallLogo}
                      id="logo"
                      className="img-responsive capital-logo"
                      alt="Capital Logo"
                      />
                    </div>
                    <p>
                      Simplifying lives with innovative and thought-driven
                      technologies! We at Capital understand market
                      demand. We ensure to get our customers solutions that fit
                      their needs and requirements. That’s how we are more
                      committed to make the difference in the industry we are
                      in. Get in touch, today!
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-lg-3">
                  <div className="footer-heading">
                    <h4>Company</h4>
                  </div>
                  <ul className="footer-links">
                    {col1.map((rows, index) => {
                      return(
                        <li><span><FontAwesomeIcon icon={faCube} style={{ color: '#40cd6c' }} size="sm" /></span><Link exact to={rows.to}>{rows.label}</Link></li>  
                        )
                    })}
                  </ul>
                </div>
                <div className="col-xs-12 col-sm-6 col-lg-3">
                  <div className="footer-heading">
                    <h4>Solutions</h4>
                  </div>
                  <ul className="footer-links">
                  {col2.map((rows, index) => {
                      return(
                        <li><span><FontAwesomeIcon icon={faCube} style={{ color: '#40cd6c' }} size="sm" /></span><Link exact to={rows.to}>{rows.label}</Link></li>  
                        )
                    })}
                  </ul>
                </div>
                <div className="col-xs-12 col-sm-6 col-lg-3">
                  <div className="footer-heading">
                    <h4><span><FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: '#40cd6c' }} size="sm" /></span>Address</h4>
                  </div>
                  <ul className="footer-links left-align">
                    <li><p>Capital Information technology System Est. Prince Badr Street, Cross-A, Al-Khobar 31952, K.S.A</p></li>
                    <li><span><FontAwesomeIcon icon={faPhoneAlt} style={{ color: '#40cd6c' }}  /></span><a href="tel:+966138960890">+(966)13-896-0890</a></li>
                    <li><span><FontAwesomeIcon icon={faEnvelope} style={{ color: '#40cd6c' }}  /></span><a href="mailto:info@capital.net.sa">info@capital.net.sa</a></li>
                  </ul>
                  {/* <ul className="footer-social-icons">
                    <li>
                      <a href="https://www.facebook.com" target="_blank">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com" target="_blank">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com" target="_blank">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://api.whatsapp.com/send?phone=243841364201&amp;text="
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title=""
                        className="applyinfo-btn"
                        target="_blank"
                        data-original-title="Hello, how may we help you?"
                      >
                        <i className="fab fa-whatsapp"></i>
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;